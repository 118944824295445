import _definePage_default_0 from '/home/enon/Desktop/enon leads/src/pages/index/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/enon/Desktop/enon leads/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from '/home/enon/Desktop/enon leads/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_4 from '/home/enon/Desktop/enon leads/src/pages/auth/register.vue?definePage&vue'
import _definePage_default_5 from '/home/enon/Desktop/enon leads/src/pages/auth/reset-pass.vue?definePage&vue'
import _definePage_default_6 from '/home/enon/Desktop/enon leads/src/pages/auth.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/enon/Desktop/enon leads/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'HomePage',
        component: () => import('/home/enon/Desktop/enon leads/src/pages/index/index.vue'),
        /* no children */
      },
  _definePage_default_0
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('/home/enon/Desktop/enon leads/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import('/home/enon/Desktop/enon leads/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('/home/enon/Desktop/enon leads/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'register',
        name: 'RegisterPage',
        component: () => import('/home/enon/Desktop/enon leads/src/pages/auth/register.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: 'reset-pass',
        name: 'ResetPass',
        component: () => import('/home/enon/Desktop/enon leads/src/pages/auth/reset-pass.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  _definePage_default_6
  )
]
