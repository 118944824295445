<script setup lang="ts">
import { getLocalStorageReac } from "./assets/js/helpers";
import { type Ref } from "vue";
import { useDefaultStore } from "./store";
import { useSessionStore } from "@/store/session";
import { useModalStore } from "./store/modal";
import ModernModal from "./components/shared/modal/ModernModal.vue";

const store = useDefaultStore();
const storeSession = useSessionStore();
const modalStore = useModalStore();
const route = useRoute();
const appBaseTitle = import.meta.env.VITE_APP_APP_NAME;

const modernModalRef = ref<InstanceType<typeof ModernModal> | null>(null);
const isAppLoading = ref(false);
const appMetaTitle = computed(() => {
	const name = route.meta.name as string;
	if (name) {
		return `${appBaseTitle} - ${(name || "")?.toString()}`;
	}
	return appBaseTitle;
});

function checkSetAppDarkTheme() {
	const settingReac = getLocalStorageReac(
		"app-color-scheme",
		false,
		"auto",
	) as Ref<any>;
	watch(
		settingReac,
		(isSetting: string) => {
			const prefersDark = window.matchMedia(
				"(prefers-color-scheme: dark)",
			).matches;
			if (isSetting === "dark" || (prefersDark && isSetting !== "light")) {
				document.documentElement.classList.add("dark");
			} else {
				document.documentElement.classList.remove("dark");
			}
		},
		{ immediate: true },
	);
}

watch(
	() => store.isMobileView,
	(val) => {
		if (val) document.body.classList.add("is-mobile");
		else if (!val) document.body.classList.remove("is-mobile");
	},
);

const canonicalLink = computed(() => {
	const baseUrl = `https://${appBaseTitle}.com`;
	return baseUrl + route.path;
});

function onCloseModalComp() {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null, true);
	} else {
		console.error("No modal instance");
	}
}

onMounted(async () => {
	await storeSession.loadSessionToken();
});

onBeforeMount(() => {
	checkSetAppDarkTheme();
});
</script>

<template>
	<teleport to="head">
		<title>{{ appMetaTitle }}</title>
		<link
			rel="canonical"
			:href="canonicalLink"
		/>
	</teleport>
	<spinner-block
		v-if="isAppLoading"
		background="#0000001a"
		color1="#f6a712"
		color2="black"
	></spinner-block>
	<template v-else>
		<router-view
			class="main-application-class"
			:class="{ 'is-mobile': store.isMobileToggled }"
		></router-view>
	</template>
	<enon-browser-detect></enon-browser-detect>
	<modern-modal
		v-if="modalStore.isGlobalModalVisible"
		:key="modalStore.getModalData('comp')"
		ref="modernModalRef"
		:config="modalStore.getModalData('config', {})"
		:form-style="modalStore.getModalData('formStyle', {})"
		@close-modal="modalStore.isGlobalModalVisible = null"
	>
		<template #content="{ slotProps }">
			<component
				:is="modalStore.getModalData('comp')"
				v-bind="slotProps"
				@close-modal="onCloseModalComp"
			></component>
		</template>
	</modern-modal>
</template>

<style lang="scss">
@import "./assets/css/base.css";
@import "https://fonts.googleapis.com/css2?family=Anton&family=Bakbak+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800&display=swap";

body {
	display: flex;

	&.is-mobile {
		overflow-y: hidden;
	}
}

#app {
	width: 100vw;
	// height: 100%;

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}
}
</style>
