<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";

definePage({
	name: "HomePage",
});

const latestBool = ref(true);
const filterType = ref<"all" | "priority" | "progress">("all");
const leads = ref<ILead[]>([]);

interface ILead {
	features: string;
	lds_design: string;
	lds_email: string;
	lds_id: number;
	lds_last_name: string;
	lds_name: string;
	lds_phone: string;
	lds_requested_date: string;
	lds_priority: string;
	lds_progress: string;
	lds_utc_created: string;
	lds_utc_deleted: string | null;
	lds_utc_updated: string | null;
	lds_comment: string;
	lds_ip: string;
}

async function getLeads() {
	const params = {
		latest: latestBool.value,
	};

	try {
		const res = await api.leadsGet(params);
		leads.value = res.data.data;
		// console.log(res.data.data);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

async function editLeads(leadId: number, priority?: string, progress?: string) {
	const params = {
		lds_id: leadId,
		priority: priority ?? undefined,
		progress: progress ?? undefined,
	};
	console.log(params);
	try {
		const res = await api.leadsEdit(params);
		const updatedLead = leads.value.find((lead) => lead.lds_id === leadId);
		const currentDate = DateInstance.getFormattedDate(
			"",
			"DD.MM.YYYY HH:mm:ss",
			false,
		);
		updatedLead.lds_utc_updated = currentDate;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

function handleEditLead({
	leadId,
	priority,
	progress,
}: {
	leadId: number;
	priority?: string;
	progress?: string;
}) {
	editLeads(leadId, priority, progress);
}

function handleSortChange() {
	getLeads();
}

onMounted(async () => {
	await getLeads();
});
</script>

<template>
	<div class="main-wrapper">
		<div class="filters-wrapper">
			<div class="filter-item first">
				<label for="latest">Sort By:</label>
				<select
					id="latest"
					v-model="latestBool"
					name="latest"
					@change="handleSortChange"
				>
					<option :value="true">Latest</option>
					<option :value="false">Oldest</option>
				</select>
			</div>
			<div class="filter-item">
				<label for="filter-type">Filter By:</label>
				<select
					id="filter-type"
					v-model="filterType"
					name="filter-type"
					@change="handleSortChange"
				>
					<option value="all">All</option>
					<option value="priority">Priority</option>
					<option value="progress">Progress</option>
				</select>
			</div>
		</div>

		<latest-comp
			v-if="filterType === 'all'"
			:leads="leads"
			@edit-lead="handleEditLead"
		/>
		<progress-comp
			v-if="filterType === 'progress'"
			:leads="leads"
			@edit-lead="handleEditLead"
		/>
		<priority-comp
			v-if="filterType === 'priority'"
			:leads="leads"
			@edit-lead="handleEditLead"
		/>
	</div>
</template>

<style scoped lang="scss">
.main-wrapper {
	padding: 24px;

	.filters-wrapper {
		.filter-item {
			&.first {
				margin-bottom: 24px;
			}
		}
	}
}

@media screen and (width > 768px) {
	.main-wrapper {
		.filters-wrapper {
			display: flex;
			margin-right: 18px;

			.filter-item {
				margin-right: 24px;

				&.first {
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
