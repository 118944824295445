import { axios } from "./config.js";
import type {
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TBasicType,
	TForgotPass,
	TLoginParam,
	TLoginRes,
	TResetPass,
} from "./types";

export default {
	// Auth
	authLogin: (params: TLoginParam) =>
		axios.post<TLoginRes>("auth/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/register", params),
	authRenewSession: () =>
		axios.post<{ data: { data: string } }>("auth/renew-jwt"),
	authLogout: () => axios.get("auth/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) =>
		axios.post("auth/forgot-password", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),
	// Leads
	leadsGet: (params: any) =>
		axios.get<TAxiosGenRes<TBasicType>>("leads/leads-get", { params }),
	leadsEdit: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("leads/leads-edit", params),
	// users
	changePassword: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("users/users-edit", params),
};
